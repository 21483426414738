import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { Advantages } from "../components/Advantages";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ContactForm from "../components/contact";

const pageData = {
  header: "Usługi serwisowe",
  mainTitle: "Serwis, który",
  subtitle: "gwarantuje zadowolenie",
  mainTitle2: "Pogwarancyjny",
  subtitle2: "serwis ekspresów",
  paragraph:
    "Zaufaj nam, a Twoje kawowe doświadczenie będzie zawsze perfekcyjne!",
  box: (
    <>
      <p className="headerTxt">
        Czym wyróżniamy się spośród innych serwisów? Nasz zespół doświadczonych
        specjalistów to gwarancja profesjonalnej obsługi. Zapewniamy kompleksowe
        naprawy, konserwacje oraz diagnozę problemów. Nasza wiedza i
        doświadczenie pozwalają nam szybko zlokalizować oraz skutecznie
        rozwiązać każdy problem, przywracając Twojemu urządzeniu pełną
        sprawność.{" "}
      </p>
      <p className="headerTxt">
        Dodatkowo, dbając o Twój komfort, oferujemy elastyczne terminy napraw
        oraz atrakcyjne ceny usług serwisowych. Niezawodność i satysfakcja
        klienta są dla nas priorytetem, dlatego każdy ekspres traktujemy
        indywidualnie, zapewniając kompleksową opiekę.
      </p>
      <p className="headerTxt">
        Zaufaj naszemu doświadczeniu i oddaj swój ekspres w ręce
        profesjonalistów. Skontaktuj się z nami już dziś, a my z przyjemnością
        pomożemy Ci cieszyć się ulubionym napojem bez obaw o stan techniczny
        Twojego urządzenia!
      </p>
    </>
  ),
};

const advElements = [
  {
    contentTitle: "Diagnostyka",
    contentTxt:
      "Zanim przystąpimy do naprawy,  zajmujemy się rozpoznaniem przyczyny zgłoszonej usterki",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk1.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Wycena",
    contentTxt:
      "Nie musisz się martwić  o to, że koszt usługi czy części Cię zaskoczy",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk2.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Czas",
    contentTxt:
      "Możesz być pewien, że nasze  usługi są dostarczane  zgodnie z harmonogramem,  bez opóźnień",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk3.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Opinie",
    contentTxt:
      "Twoje zadowolenie z naszego  serwisu oraz jakości obsługi  jest dla nas priorytetem",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk4.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Wsparcie",
    contentTxt:
      "Kompleksowe wsparcie  na każdym etapie  zlecenia - od instalacji  po regularne serwisy  i wsparcie techniczne",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk5.svg").default,
    alt: "Opis obrazka",
  },
];
const advElements2 = [
  {
    contentTitle: "Diagnoza i wycena",
    contentTxt:
      "Zanim przystąpimy do naprawy,  zajmujemy się rozpoznaniem przyczyny zgłoszonej usterki",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk6.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Naprawa i konserwacja",
    contentTxt:
      "Nie musisz się martwić  o to, że koszt usługi czy części Cię zaskoczy",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk7.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Czyszczenie i regulacja",
    contentTxt:
      "Możesz być pewien, że nasze  usługi są dostarczane  zgodnie z harmonogramem,  bez opóźnień",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk8.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Wymiana zużytych części",
    contentTxt:
      "Twoje zadowolenie z naszego  serwisu oraz jakości obsługi  jest dla nas priorytetem",
    imgSrc: require("../assets/images/serviceOffer/ikony_druk9.svg").default,
    alt: "Opis obrazka",
  },
];

const advElements3 = [
  {
    imgSrc: require("../assets/images/serviceOffer/krups_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/delonghi_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/saeco_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/jura_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/philips_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/nivona_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/mellita_grey.svg").default,
    alt: "Opis obrazka",
  },
  {
    imgSrc: require("../assets/images/serviceOffer/siemens_grey.svg").default,
    alt: "Opis obrazka",
  },
];

const ServicePage = () => {
  const [active, setActive] = useState("active");
  useEffect(() => {
    window.addEventListener("load", function () {
      return setActive("active");
    });
  }, []);
  return (
    <>
      <div className={`sign-page-info ${active}  service-offer-page-info`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          // date={pageData.date}
          subtitle={pageData.subtitle}
        />
      </div>
      <Layout>
        <section className="sign-page registers-page services-offer-page">
          <div id="info" className="anchor"></div>
          <div className="aboutUs-container top">
            <div className="imgContainer">
              <StaticImage src="../assets/images/serviceOffer/czym_serwis.jpg" />
              <div className="square"></div>
            </div>
            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Przekonaj się <br />{" "}
                  <strong>o jakości naszego serwisu</strong>
                </h2>
              </div>
              <p className="headerTxt">
                W naszym serwisie, gdzie <b>profesjonalizm</b> spotyka się z{" "}
                <b>pasją</b> do doskonałego funkcjonowania sprzętu. Jesteśmy
                ekspertami w obszarze serwisu sprzętów komputerowych i ekspresów
                do kawy, oferując kompleksowe rozwiązania dla Twoich potrzeb.
              </p>
              <p className="headerTxt">
                Nasi eksperci to zespół doświadczonych specjalistów, którzy nie
                tylko posiadają głęboką wiedzę techniczną, ale także pasję do
                perfekcji. Ich zaangażowanie gwarantuje, że{" "}
                <b>Twoje urządzenia są w najlepszych rękach,</b> niezależnie od
                tego, czy chodzi o naprawę komputera czy konserwację ekspresu do
                kawy.
              </p>
              <p className="headerTxt">
                Nieustannie podnosimy poprzeczkę naszych usług, zapewniając nie
                tylko <b>wysoką jakość napraw, </b>ale także indywidualne
                podejście do każdego klienta. Nasz profesjonalizm oznacza nie
                tylko skuteczne rozwiązanie problemów, ale również dbałość o
                detale i <b>zadowolenie klienta</b> na każdym etapie współpracy.
              </p>
              <Link to="#devices">
                <button className="btn-red right">Dowiedz się więcej</button>
              </Link>
            </div>
          </div>
          <Advantages
            headerSubtitle={
              <>
                Dlaczego warto <br /> <strong>wybrać nasz serwis</strong>
              </>
            }
            headerTxt={
              "Nasz serwis urządzeń oferuje znaczące korzyści, które sprawiają, że jesteśmy najlepszym wyborem dla Twojej firmy"
            }
            advantageElements={advElements}
          />
          <div className="aboutUs-container onlyTxt">
            <div className="aboutUs txt">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container">
                <h2>
                  Jakie urządzenia <br /> <strong>serwisujemy?</strong>
                </h2>
              </div>
              <p className="headerTxt"></p>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> drukarki, skanery, urządzenia wielofunkcyjne
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> komputery stacjonarne
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> laptopy
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> ekspresy do kawy
                </p>
              </div>
              <div className="txt-container-wrapper">
                <p className="headerTxt">
                  <b></b> małe AGD
                </p>
              </div>
              <p className="headerTxt">
                Nie znalazłeś sprzętu na liście? To nie znaczy, że go nie
                naprawimy! Skontakuj się z znami w celu potwierdzenia.
              </p>
            </div>
            <div className="imgContainer bottom">
              <StaticImage src="../assets/images/serviceOffer/urzadzenia-serwisowe2.png" />
              <div className="square"></div>
            </div>
          </div>
          <div id="devices" className="anchor"></div>
          <div className={`sign-page-info ${active} qual-sign service-offer`}>
            <div className="imgContainer">
              <StaticImage src="../assets/images/serviceOffer/serwis_ekspresow_bg.jpg" />
              <div className="square"></div>
            </div>
            <div className="services-offer-page-info middle">
              <PageInfo
                mainTitle={pageData.mainTitle2}
                subtitle={pageData.subtitle2}
                paragraph={pageData.paragraph}
                box={pageData.box}
              />
            </div>
          </div>
          <div className="advantages-middle">
            <div className="first">
              <Advantages
                headerSubtitle={
                  <>
                    Nasze <b>usługi serwisowe</b>
                  </>
                }
                advantageElements={advElements2}
              />
            </div>
            <div className="second">
              <Advantages
                headerSubtitle={<>Serwisujemy urządzenia marek:</>}
                advantageElements={advElements3}
              />
            </div>
          </div>
          <div className="aboutUs-container ">
            <div className="aboutUs txt first">
              <div id="abUs" className="anchor"></div>
              <div className="aboutUs txt-container ">
                <h2>
                  Serwis <br /> <strong>sprzętu komputerowego</strong>
                </h2>
              </div>
              <p className="headerTxt">
                Naszym celem jest zapewnienie pełnej funkcjonalności Twoich
                urządzeń. Dzięki <b>zespołowi doświadczonych specjalistów,</b>{" "}
                jesteśmy w stanie szybko zidentyfikować problemy i skutecznie je
                rozwiązać. Bez względu na markę czy model, naprawiamy komputery,
                laptopy oraz urządzenia małego AGD.
              </p>
              <p className="headerTxt">
                Oferujemy nie tylko naprawy, ale także{" "}
                <b>profesjonalne doradztwo techniczne</b> oraz usługi
                konserwacyjne, aby zapewnić długotrwałą wydajność i niezawodność
                Twojego sprzętu. Elastyczne terminy napraw, konkurencyjne ceny
                oraz <b>indywidualne podejście do każdego Klienta</b> to nasza
                recepta na satysfakcję.
              </p>
              <p className="headerTxt">
                Zaufaj nam, jeśli szukasz zaawansowanej naprawy elektroniki czy
                drobnego AGD. Oddaj swoje urządzenia w ręce ekspertów, którzy
                <b> z pasją i zaangażowaniem </b>przywrócą im pełną sprawność.
              </p>
            </div>
            <div className="imgContainer bottom">
              <StaticImage src="../assets/images/serviceOffer/serwis_it.jpg" />
              <div className="square"></div>
            </div>
          </div>
        </section>
        <div className="footer-container-wrapper-form">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
            <div className="form-txt">
              <h2>Specjalista</h2>
              <p>ds. serwisowych</p>
              <br />
              <p>
                Tel: <b>+48 83 344 70 85</b>
              </p>
              <br />
              <p>
                e-mail: <b>serwis@jbmultimedia.pl</b>
              </p>
            </div>
          </div>

          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => <Seo title="Usługi serwisowe" />;
export default ServicePage;
